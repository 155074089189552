class Deducer {
  constructor(hooks, d) {
    this.hooks = hooks;
    this.deduce = d;
  }

  subscribeOn(o) {
    this.hooks.forEach((element) => {
      o.bind(element, this.deduce.bind(this));
    });
  }
}

/* eslint-disable no-param-reassign */
const licenceDeducer = new Deducer(
  ['site', 'grade', 'qualifLOC'],
  (s) => {
    let niveauLic = 0;
    switch (s.input.grade) {
      case 'élève':
        break;
      case 'stagiaire':
      case 'normal':
        if (['LFPG', 'LFPO'].includes(s.input.site)
            && s.input.qualifLOC
            && s.input.qualifLOC !== '0') {
          niveauLic = s.input.grade === 'stagiaire' ? 1 : 4;
        }
        break;
      default:
        if (s.input.groupe) {
          niveauLic = {
            G: 2,
            F: 3,
            E: 4,
            D: 4,
            C: 4,
            B: 5,
            A: 5,
          }[s.input.groupe[0]];
        }
    }
    s.deduced.licence = niveauLic;
  },
);

const pcsDeducer = new Deducer(
  ['site', 'corps'],
  (s) => {
    console.log('pcsDeducer');
    if (s.input.siteData?.pcs === '1') {
      s.deduced.pcs = s.input.corps === 'ieeac' ? 100 : 150;
    }
  },
);

const deducers = [
  licenceDeducer,
  pcsDeducer,
];

export {
  licenceDeducer,
  deducers,
};
