<template>
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="comp">
    <h3>Mode détaillé</h3>
    <p class="legend">
      Ce menu permet de forcer certains paramètres pour gérer les cas particuliers.
    </p>

    <AnimatedSelector
      label="Corps :"
      :items="['iessa','ieeac','icna','tseeac'].map(it=>({
        desc:it.toUpperCase(),
        value:it}))"
      v-model=input.corps
    />
    <SitePicker
      :corps="input.corps"
      :currentSite="input.site"
      @site="receiveSite"
    />
      <h4>Indiciaire</h4>
      <label for="grade">Grade</label>
        <select v-model='input.grade' name="grade">
          <option value="élève">Élève</option>
          <option value="stagiaire">Stagiaire</option>
          <option value="normal">Normal</option>
          <option value="principal">Principal</option>
          <option value="divisionnaire">Divisionnaire</option>
          <option value="chef">Chef</option>
          <option value="détaché">Détachement Emploi Fonctionnel</option>
        </select>
      <label for="EF">EF</label>
        <select v-model='input.detachement' :disabled="input.grade != 'détaché'" name="EF">
          <option value="RTAC">RTAC</option>
          <option value="CTAC">CTAC</option>
          <option value="CSTAC">CSTAC</option>
          <option value="CUTAC">CUTAC</option>
          <option value="CST">CST</option>
          <option value="CSTP">CSTP</option>
        </select>
      <AnimatedTextInput label="Échelon :" v-model="input.echelon"/>
      <AnimatedTextInput label="Indice Majoré" v-model="input.indiceMajo" />
      <toggle-button
        label="NBI"
        title="Accordé sous condition, ou au plus tard à 35 ans"
        :map="{true:1,false:0}"
        v-model="input.nbi"
      />
    <h4>RIST</h4>
    <AnimatedTextInput label="Fonction" v-model="input.evs" />

    <RistExperience
      :corps='input.corps'
      v-model="input.ristXP"
      v-if="input.grade == 'divisionnaire' && ['icna', 'iessa'].includes(input.corps)" />

    <template v-if="input.corps=='iessa'">
      <PEQIessa
        v-model="input.peq"
      />
      <AnimatedSelector
        label="Réorg 2016"
        v-model="input.reorg"
        :items="expe2016IessaLevels"
      />
    </template>
    <AnimatedSelector
      label="Part Licence"
      v-model="input.licence"
      :items="partLicenceLevels"
      v-if="input.corps=='icna'"
    />

    <AnimatedSelector
      label="Groupe de terrain"
      v-model="input.groupe"
      :items="genGroupe"
      v-if="['icna', 'tseeac'].includes(input.corps)"
    />

    <template v-if="input.corps == 'tseeac'">
      <AnimatedTextInput
        label="Index prime part Tech"
        v-model="input.partTechTSHabil"
      />
    </template>

    <h4>Majorations géographiques</h4>
    <AnimatedSelector
      label="Zone de résidence"
      v-model="input.region"
      :items="[
        {desc: '0%', value:0},
        {desc: '1%', value:1},
        {desc: '3%', value:3},
      ]"
    />

    <AnimatedSelector
      label="Majo Nord/Nord-Est"
      v-model="input.pcs"
      :items="[
        {desc: 'Logement de fonction', value:60},
        {desc: 'Horaires de bureau', value:100},
        {desc: 'Opérationnel', value:150},
      ]"
      v-show='input.siteData?.pcs == "1"'
    />

    <h4>Date de validité</h4>
    <input
      type="date"
      id="validityWidget"
      name="validity"
      :value="validityDate.toISOString().slice(0,10)"
      min="2018-01-01"
      :max="new Date().toISOString().slice(0,10)"
      @change="changeDate"
    />

    <a id="bugreport" :href="genMailTo">✉ Envoyer un rapport de bug</a>
  </div>
</template>

<style scoped>
/* layout */
.comp {
  grid-column: 1 / span 4;
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  height:fit-content;
}
h3, h4, a, p.legend {
  grid-column: span 4;
}
p.legend {
  font-style: italic;
}
select {
  grid-column: span 3;
}
.animated-input {
  grid-column: span 4;
  display: grid;
  grid-template-columns: subgrid;
}
a {
  margin-top:.5em;
  text-align: center;
}
#validityWidget {
  grid-column: 2 / 4;
}
#bugreport {
  margin-top: 2.5em;
}
</style>
<style>
/* FIXME */
.animated-input > select {
  grid-column: span 3;
}
</style>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import ToggleButton from '@/components/input/widgets_general/ToggleButton.vue';
import AnimatedTextInput from '@/components/input/widgets_general/AnimatedTextInput.vue';
import AnimatedSelector from '@/components/input/widgets_general/AnimatedSelector.vue';
import PEQIessa from '@/components/input/widgets_specific/PEQIessa.vue';
import RistExperience from '@/components/input/widgets_specific/RistExperience.vue';
import SitePicker from '@/components/input/wizard/SitePicker.vue';

import { frenchDatestrToDate, dateToFrenchDatestr } from '../../../../js/utils';

const store = useStore();
const { input } = store.state;

const dp = computed(() => store.getters.paysheet.dp);

const partLicenceLevels = computed(() => [...dp.value.partLicenceLevel].map((item) => ({
  desc: item.name,
  value: item.partLicence,
})));

const expe2016IessaLevels = computed(
  () => Object.entries(dp.value.expe2016IessaLevel).map(([key, value]) => ({
    desc: value,
    value: key,
  })),
);

const genGroupe = computed(() => {
  const groupes = ['A6', 'A5', 'A4', 'A3', 'A2', 'A1', ...'BCDEFG'];
  return groupes.map((l) => ({
    desc: `Groupe ${l}`,
    value: l,
  }));
});

const genMailTo = computed(() => (
  'mailto:simulateur-paye@usac-cgt.org?subject=rapport de bug&body=Bonjour,%0d%0a'
  + 'J\'ai rencontré un problème avec les paramètre ci-dessous.%0d%0aJe m\'attendais à: %0d%0a'
  + `%0d%0a%0d%0a%0d%0a ${JSON.stringify(input, null, 2).replace(/\n/g, '%0d%0a')}`
  + `%0d%0a%0d%0aVersion ${process.env.VUE_APP_SEMVER_STRING}`
));

function receiveSite(newSite) {
  store.dispatch('setSite', newSite);
}

const validityDate = computed(() => frenchDatestrToDate(input.validity));

function changeDate(e) {
  const d = new Date(e.target.value);
  store.dispatch('setDate', dateToFrenchDatestr(d));
}

</script>

<script>
export default {
  components: {
    AnimatedTextInput,
    PEQIessa,
    SitePicker,
    RistExperience,
  },
  name: 'ExpertForm',
};
</script>
