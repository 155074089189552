
/* point indice mensuel
   Les décrets indiquent la valeur annuelle à l'indice 100
   Ex, 1er juillet 2023:
       5907,34 / 1200 = 4.92278
*/
export const point_indice = {
    "1/1/2015" : 4.6303,
    "1/7/2016" : 4.6581,
    "1/2/2017" : 4.68602,
    "1/7/2022" : 4.85003,
    "1/7/2023" : 4.92278,
};

// retenue pension civile
export const rpc_rate = {
    "2016" : 9.94,
    "2017" : 10.29,
    "2018" : 10.56,
    "2019" : 10.83,
    "2020" : 11.10
};

export const rpc_pat_rate = {
    '2013' : 74.28,
}

export const indemnite_nuit = 8.73;

export const indemnite_teletravail = {
    "1/1/2016": 0,
    "1/9/2021": 2.50,
    /* FIXME actual date is 1/1/2023.
      Temporary left wrong on purpose to comply with test */
    "1/11/2023": 2.88,
}

export const pcs = {
    "1/1/2015": {
        "100":102.40,
        "150":153.6,
    },
    "1/7/2016": {
        "100":103.01,
        "150":152.52
    },
    "1/2/2017": {
        "60": 62.18,
        "100": 103.63,
        "150": 155.44,
    },
    "1/1/2023": {
        "60": 64.36,
        "100":107.26,
        "150":160.88
    },
    "1/1/2024": {
        "60": 65.32,
        "100":108.87,
        "150":163.29
    },

};

export const points_nbi = {
    "tseeac" : 65,
    "icna" : 55,
    "ieeac" : 75,
    "iessa" : 75
};

export const validityDates = [
    "01/07/2017",
    "01/01/2018",
    "01/01/2019",
    "01/01/2020",
    "01/07/2022",
    "01/01/2023",
    "01/07/2023",
];

//retenue à ajouter dans le cadre du transfert primes/points
export const transfertRetenue = {
    "A": {
        "2016": 0,
        "2017": 13.92,
        "2019": 32.42,
    },
    "B": {
        2016: 23.17
    }
};

export const csg_deduc = {
    "2017": 5.1,
    "2018": 6.8
};

//modulation de la part Fonction pour les IEEAC RMA
export const modulationRMA = 100;

//modulation de la part Fonction pour les IESSA de CDG
export const modulationCDGIessa = {
    "2017": 120,
    "2023": 124.20,
    "2024": 126.06,
}


export const modulationCDGIcna = {
    2017: {
        "b": 120,
        "c": 165,
        "d": 428,
        "e": 673.67,
        "f": 685.56,
        "g": 703.39,
        "h": 715.38,
        "i": 725.19,
        "j": 734.56,
    },
    2023: {
        "b": 124.20,
        "c": 170.78,
        "d": 442.98,
        "e": 697.25,
        "f": 709.55,
        "g": 728.01,
        "h": 740.31,
        "i": 750.57,
        "j": 760.27,
    },
    2024: {
        "b": 126.06,
        "c": 173.34,
        "d": 449.62,
        "e": 707.71,
        "f": 720.20,
        "g": 738.93,
        "h": 751.42,
        "i": 761.83,
        "j": 771.67,
    },
}

export const modulationLFRRIcna = {
    2023: {
        9: 149.57,
        10: 156.8,
        11: 167.67,
        12:0,
    },
    2024: {
        9: 151.81,
        10: 159.15,
        11:170.19,
        12: 0,
    }
}

export const modulationCDGIcnaLevel = [
    {name: "Spécialiste", modulationCDGIcna:"b"},
    {name: "Ensemble des MU", modulationCDGIcna:"c"},
    {name: "4 ans de MU (ou mutation avec mention LOC)", modulationCDGIcna:"d"},
    {name: "6 ans de MU, EVS 9", modulationCDGIcna:"e"},
    {name: "6 ans de MU, EVS 10", modulationCDGIcna:"f"},
    {name: "6 ans de MU, EVS 11", modulationCDGIcna:"g"},
    {name: "6 ans de MU, EVS 12", modulationCDGIcna:"h"},
    {name: "6 ans de MU, EVS 13", modulationCDGIcna:"i"},
    {name: "6 ans de MU, EVS 14 ou 15", modulationCDGIcna:"j"},
];

export const domtom = {
    "mq": 1.4,
    "gp": 1.4,
    "gf": 1.4,
    "re": 1.53,
    "yt": 1.53,
    "pf-vent": 1.84,
    "pf-other": 2.08,
    "nc-ville": 1.73,
    "nc-other":1.94,
    "wf": 2.05,
    "pm": 1.4,
}

export const sft_fixe = {
    "1": 2.29,
    "2": 10.67,
    "3": 15.24,
    "4": 4.57
};

export const sft_prop = {
    "1": 0,
    "2": 3,
    "3": 8,
    "4": 6
};


export const tauxAboTransport = {
    "1/7/2016": .5,
    "1/9/2023": .75,
}