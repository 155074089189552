<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="toggle-button">
    <label :for="myId">{{label}}</label>
    <input type=checkbox :id="myId" :name="myId"
      @change="$emit('change',getValue());$emit('update:modelValue', getValue())"
      v-model="internal"
    >
  </div>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  ref,
  defineEmits,
  watch,
} from 'vue';

const props = defineProps(['label', 'modelValue', 'map']);
const myId = ref();
const internal = ref(false);

const flip = (data) => Object.fromEntries(
  Object
    .entries(data)
    .map(([key, value]) => [value, key]),
);

function getValue() {
  const output = props.map ? props.map[internal.value] : internal.value;
  // console.log(`${myId.value}.getValue()|${internal.value}|=>${output}`);
  return output;
}

function decodeValue(v) {
  const output = props.map ? flip(props.map)[props.modelValue] : props.modelValue;
  // console.log(`${myId.value}.decodeValue!(${props.modelValue})|=>${output}`);
  return output;
}

watch(() => props.modelValue, (newV, oldV) => {
  internal.value = decodeValue(newV);
});

onMounted(() => {
  myId.value = `ToggleButton${getId()}`;
});

defineEmits(['change', 'update:modelValue']);
</script>

<script>
export default {
  name: 'ToggleButton',
};

let counter = 0;
function getId() {
  counter += 1;
  return counter;
}
</script>
