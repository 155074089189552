/**
 * Copyright (C) 2016-2022 Bruno Spyckerelle
 * Copyright (C) 2022-2023 USACcgt
 * License AGPL 3.0
 *
 * This program is free software: you can redistribute it and/or modify it under the terms of the
 * GNU Affero General Public License as published by the Free Software Foundation, version 3.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY;
 * without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See
 * the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License along with this
 * program. If not, see <https://www.gnu.org/licenses/>
 *
 */

import {Observer, getGetterPropnames} from "./utils.js"
import { Payline } from "./payline.js"

class PaySheet {
  constructor(dataProvider, agent) {
    this.dp = dataProvider;
    this.agent = agent;

    this.lines = {};
    this.total_pos = 0;
    this.retenues = 0;
    this.patronales = 0;
    this.horsImpots = 0;

    this.gestionIndiciaire();
  }

  pushLine(l) {
    this.lines[l.ref]=l;
    try {
      // backward compat
      this[l.shortname] = l.amount;
    } catch (e) {
      if (e instanceof TypeError) {
        // ignore non writable getter
      } else {
        throw e;
      }
    }

    if (l.sign == '+') {
        this.total_pos += l.amount;
    } else if (l.sign == '-') {
        this.retenues += l.amount;
    } else if (l.sign == '0') {
        this.patronales += l.amount;
    } else if (l.sign == 'horsimpot') {
        this.horsImpots += l.amount;
    }
  }

  gestionIndiciaire () {
    this.pushLine(new Payline("traitement_brut", 101000,"TRAITEMENT BRUT", this.traitement_brut));
    this.pushLine(new Payline("nbi", 101070, "TRAITEMENT BRUT N.B.I.", this.nbi))
    //retenue pour pension civile
    this.pushLine(new Payline("rpc",101050,"RETENUE PC",this.rpc,"-"))
    let rpc_pat = this.traitement_brut * this.dp.rpc_pat_rate / 100;
    this.pushLine(new Payline("rpc_pat",411050 ,"CONTRIB.PC",rpc_pat,"0"))

    //retenue PC NBI
    var rpcnbi = this.nbi * this.dp.rpc_rate / 100;
    let rpcnbi_pat = this.nbi * this.dp.rpc_pat_rate / 100;
    this.pushLine(new Payline("rpcnbi",101053,"RETENUE PC NBI",rpcnbi,"-"))
    this.pushLine(new Payline("rpcnbi_pat",411053, 'CONTRIB.PC NBI',rpcnbi_pat,"0"))
  }

  get indice() {
    if (!this._indice) {
      if (this.agent.indiceMajo && parseInt(this.agent.indiceMajo) > 0) {
        // indice forcé par le champs indiceMajo
        this._indice = parseInt(this.agent.indiceMajo);
      } else {
        //calcul de l'indice depuis (corps,grade,echelon)
        // retrocompat jekyll (valeurs par défaut)
        let corps = this.agent.corps || "ieeac"
        var grade = this.agent.grade || "élève";
        let echelon = this.agent.echelon || 1;

        let myGrille = this.dp.getEchelons(corps, grade, this.agent.detachement);
        this.indice_brut= myGrille?.[echelon]

        this._indice = parseInt(this.dp.table_indices[this.indice_brut]);
        if (isNaN(this._indice)) {
          this._indice = 0;
        }
      }
    }
    return this._indice;
  }

  get traitement_brut () {
    const _point_indice = this.dp.point_indice;
    return Math.round(this.indice*_point_indice*100)/100;
  }

  get nbi_points () {
    if (! this._nbi_points) {
      var nbi = parseInt(this.agent.nbi);
      if(isNaN(nbi)) {
        this._nbi_points = 0;
      } else {
        this._nbi_points = nbi * this.dp.points_nbi[this.agent.corps]
      }
    }
    return this._nbi_points;
  }

  get nbi() {
    return Math.floor(this.nbi_points * this.dp.point_indice * 100) / 100;
  }

  get rpc() {
    return this.traitement_brut * this.dp.rpc_rate / 100;
  }

  get traitement_net() {
    return this.traitement_brut - this.rpc;
  }

  extractGetter() {
    let acc = {};
    getGetterPropnames(this).forEach(n=>acc[n] = this[n]);
    return acc;
  }

  prettyPrint() {
    let acc = Object.values(this.lines) .map((l)=>l.toString());
    acc.push(`total_pos: ${this.total_pos}`);
    acc.push(`total: ${this.total}`);
    console.log(acc.join("\n"));
  }
}

/**
 * fire "update" message
 */
export class PaySheetComputer extends Observer {
  constructor (dataProvider) {
    super();
    this.dp = dataProvider;
    this.bonusComputers = [];
    this.registerLineComputer(new IndemResidence(dataProvider));
    this.registerLineComputer(new MajorationDOMTOM(dataProvider));
    this.registerLineComputer(new IndemAlsace(dataProvider));
    this.registerLineComputer(new TransfertPrimePoint(dataProvider));
    this.registerLineComputer(new PCS(dataProvider));

    this.registerLineComputer(new RISTFonction(dataProvider))
    this.registerLineComputer(new RISTExperience(dataProvider))
    this.registerLineComputer(new RISTTechnique(dataProvider))
    this.registerLineComputer(new RISTXP2016(dataProvider));

    this.registerLineComputer(new PSCMutuelle(dataProvider));

    this.registerLineComputer(new RemboursementDomicileTravail(dataProvider));
    this.registerLineComputer(new ForfaitNuit(dataProvider));
    this.registerLineComputer(new ForfaitTeletravail(dataProvider));
  }
  update(input) {
    let paysheet = this.compute_income(input);
    this.fire("update",paysheet);
  }

  registerLineComputer(b) {
    this.bonusComputers.push(b);
  }
  compute_income(input) {
    console.log({...input});

    let ps = new PaySheet(this.dp, input);
    var temp;

    const linePusher = (l) => ps.pushLine(l);

    this.bonusComputers.forEach(b=> {
        let bl = b.compute(input, ps);
        if (bl) {
            if (Array.isArray(bl)) {
                bl.forEach(linePusher)
            } else {
                linePusher(bl);
            }
        }
    })

    //supplément familial
    var indiceSFT = Math.min(Math.max((ps.indice+ps.nbi_points), 449), 717);
    var nombreEnfants = 0;
    var enfants = parseInt(input.famille);
    if(!isNaN(enfants)) {
        nombreEnfants = enfants;
    }
    //nombre d'enfants sup à 3
    var enfantsSupp = Math.max(0, nombreEnfants - 3);
    //nombre d'enfants <= 3
    var enfantsMoins = Math.min(nombreEnfants, 3);
    var sft = 0;
    if(nombreEnfants > 0) {
        sft = this.dp.sft_fixe[enfantsMoins]
            + this.dp.sft_prop[enfantsMoins] / 100 * indiceSFT * this.dp.point_indice
            + enfantsSupp * (this.dp.sft_fixe["4"] + this.dp.sft_prop["4"] / 100 * indiceSFT * this.dp.point_indice);
        sft = Math.round(sft*100)/100;
    }
    linePusher(new Payline("sft",104000,"SUPP FAMILIAL TRAITEMENT ",sft))

    //calculs spécifiques avant ou après RIST

    var compCSG = 0;
    temp = parseFloat(input.indem_csg);
    if(!isNaN(temp)) {
        compCSG = temp;
    }

    let cotisATC = ps.lines[751095] ? ps.lines[751095].amount : 0;

    //rafp
    var rafp = 0;
    var base_rafp = ps.total_pos - (ps.traitement_brut + ps.nbi + sft);
    if(base_rafp < ps.indice * this.dp.point_indice * 20 / 100) {
        rafp = 5 / 100 * base_rafp;
    } else {
        rafp = 5 / 100 * 20 / 100 * ps.indice * this.dp.point_indice;
    }
    linePusher(new Payline("rafp", 501080, 'COT SAL RAFP',rafp,"-"));
    linePusher(new Payline("rafp_pat", 501180 , 'COT PAT RAFP',rafp,"0"));

    //contribution solidarité
    //supprimée lors de l'augmentation de la csg en 2018
    var cs = 0;
    if (this.dp.beforeCSG()) {
        cs = (total_pos - rpc - rafp) * 1 / 100;
        retenues += cs;
    }
    if( ! this.dp.beforeCSG()) {
        linePusher(new Payline('compCSG', 202206, 'IND. COMPENSATRICE CSG', compCSG))
    }

    let assietteCSG =
            ps.total_pos
            - ps.lines[604970].amount // transfert prime point
            - cotisATC;
    ps.assietteCSG = assietteCSG;

    //csg
    var csg_deduc = 98.25 / 100 * this.dp.csg_deduc / 100 * (assietteCSG);
    var csg_non_deduc = 98.25 / 100 * 2.4 / 100 * (assietteCSG);
    linePusher(new Payline('csg_deduc', 401301, 'C.S.G. DEDUCTIBLE', csg_deduc, '-'));
    linePusher(new Payline('csg_non_deduc', 401201 , 'C.S.G. NON DEDUCTIBLE', csg_non_deduc, '-'));

    //crds
    let crds = assietteCSG * 98.25 /100 * 0.5 / 100;
    linePusher(new Payline('crds', 401501, 'C.R.D.S.', crds, '-'))

    new CotisPatronaleSecu().compute(input, ps).forEach(l => linePusher(l));

    let cotisPatronales = Object.values(ps.lines).filter(l=>l.sign =="0").reduce((acc, v) => acc + v.amount, 0);

    var totalImposable = ps.total_pos
        - ps.retenues + csg_non_deduc + crds;

    var retenueIR = 0;
    if( ! this.dp.beforeIR()) {
        var irTaux = parseFloat(input.tauxIR);
        if(!isNaN(irTaux)) {
            retenueIR = round2Cents(totalImposable * irTaux / 100);
        }
    }

    var total = round2Cents(ps.total_pos - ps.retenues + ps.horsImpots)

    var netir = round2Cents(total - retenueIR);

    if (isNaN(total)) {
        ps.prettyPrint();
    }

    return {
        ...ps,
        ...ps.extractGetter(),
        indice: ps.indice,
        crds,
        cs, //outdated ?
        retenueIR,
        totalImposable,
        total,
        cotisPatronales,
        netir,
    }
  }
}


const fakeCodesMax = {};
function getFakeCode(prefix) {
    if (prefix==undefined) {
        prefix = 9
    }
    if (!(prefix in fakeCodesMax)) {
        fakeCodesMax[prefix] = 100000
    }
    let current = fakeCodesMax[prefix]-1;
    fakeCodesMax[prefix] = current;
    return prefix * 100000 + current;
}

class Bonus {
    constructor(dataProvider) {
        this.dp = dataProvider;
    }
    compute(agent) {
        console.warn("should be overloaded")
    }
}

class RISTFonction extends Bonus {
    compute(agent, ps) {
        var niveauEVS = this.getNiveauEvs(agent);
        var partFonction = 0;
        if(!isNaN(niveauEVS) && niveauEVS in this.dp.evs) {
            partFonction = parseFloat(this.dp.evs[niveauEVS]);
        }

        //modulation géographique N/NE de la part Fonction
        //à ne pas confondre avec la majoration N/NE, ancienne PCS
        var modulationPF = 0;

        var maj = 1;
        let temp = agent.affect ||"";
        if(temp.localeCompare("n-ne") == 0) {
            maj = 1.05;
        }

        if(agent.corps == "ieeac" && maj > 1 && !isNaN(niveauEVS)) {
            modulationPF = this.dp.modulationGeoIEEAC[niveauEVS];
        }

        if(agent.rma) {
            //FIXME access through global, check if time
            // dependant
            modulationPF += this.dp.modulationRMA;
        }

        if (["LFEE", "LFFF", "LFPO"].includes(agent?.site) && agent.corps == "icna") {
          /* majo par fonction à PC+9 */
          if (
              (agent.grade === "divisionnaire" && agent.echelon >= 4)
              || (["en chef", "détaché"].includes(agent.grade))) {
                partFonction *= 1.2;
              }
        }

        modulationPF += this.getModulation(agent, ps)
        modulationPF = Math.min(.2*partFonction, modulationPF);

        return  [
            new Payline("partFonction",201958,"RIST PART FONCTIONS",partFonction),
            new Payline("modulationPF",201986,"RIST MAJO CDG/CRNAO",modulationPF), //FIXME
        ]

    }

    getNiveauEvs(agent) {
        return parseInt(agent.evs);
    }

    getModulation(agent, ps) {
        if (agent.site == 'LFPG'
            || agent.corps == 'iessa' && agent.majPFRistCDG /* retrocompat */) {
            return this.primeCDG(agent, ps);
           }
        if (agent.site == 'LFRR') {
            return this.primeBrest(agent, ps);
        }
        return 0;
    }

    primeCDG(agent,ps) {
        if ( agent.corps == 'iessa' &&
            (
                agent.site == 'LFPG'
                || agent.majPFRistCDG //retrocompat
            )
        ) {
            return this.dp.modulationCDGIessa;
        }
        return 0;
    }

    primeBrest(agent, ps) {
        if (agent.corps == 'icna') {
            let evs = this.getNiveauEvs(agent);
            if (!isNaN(evs)) {
                while (evs >= 0 && !(evs in this.dp.modulationLFRRIcna)) {
                    evs --;
                }
                if (evs in this.dp.modulationLFRRIcna) {
                    return this.dp.modulationLFRRIcna[evs];
                }
            }
        }
        return 0;
    }

}

class RISTExperience extends Bonus {
    compute(agent, ps) {
        var partExp = 0;
        let _exp = this.dp.partExperience;
        let grade = agent.grade;
        switch (agent.corps) {
            case "ieeac":
                if(typeof grade != "undefined") {
                    if(grade.localeCompare("élève") == 0) {
                        //pas de prime
                    } else if(grade.localeCompare("normal") == 0) {
                        partExp = _exp[4];
                    } else {
                        partExp = _exp[5];
                    }
                }
                break;
            case "iessa":
            case "icna":
                if (typeof grade != "undefined") {
                    if (agent.ristXP) {
                        partExp = _exp[agent.ristXP];
                        break;
                    }
                    if (grade.localeCompare("élève") == 0) {
                        //pas de prime
                    } else if(grade.localeCompare("stagiaire") == 0) {
                        partExp = _exp[1];
                    } else if(grade.localeCompare("normal") == 0) {
                        partExp = _exp[3];
                    } else if(
                        grade.localeCompare("principal") == 0
                        || ( grade.localeCompare("divisionnaire")==0 && agent.echelon <= 7)
                        ) {
                        partExp = _exp[4];
                    } else {
                        partExp = _exp[5];
                    }
                }
                break;
            case "tseeac":
                if (typeof grade != "undefined") {
                    if (grade.localeCompare("élève") == 0) {
                        //pas de prime
                    } else if(grade.localeCompare("stagiaire") == 0) {
                        partExp = _exp[1];
                    } else if(grade.localeCompare("normal") == 0) {
                        partExp = agent.TSEtape > 2 ? _exp[3] : _exp[2];
                    } else if (grade.localeCompare("principal") == 0) {
                        partExp = agent.TSEtape > 4 ? _exp[4] : _exp[3];
                    } if (grade.localeCompare("exceptionnel") == 0) {
                        partExp = _exp[4];
                    };
                };
                break;
            default:
        }
        return new Payline("partExp", 201959, "RIST PART EXPER. PROF", partExp);
    }
}

class RISTXP2016 extends Bonus {
    compute(agent, ps) {
        let rist_xp = 0;
        switch (agent.corps) {
            case 'iessa':
                rist_xp = this.reorgST2016(agent, ps);
                break;
            case 'icna':
                rist_xp = this.xpRhIcna2016(agent, ps);
                break;
        }
        return new Payline("rist_xp", 201962 , "RIST CPLT EXPERIMENTATION", rist_xp );
    }

    reorgST2016(agent, ps) {
        let reorg_status;
        if (agent.reorg) {
            //retrocompat
            reorg_status = agent.reorg;
        } else if (agent.siteData?.reorg != undefined) {
            reorg_status = agent.siteData.reorg;
        }
        return this.dp.expe2016["iessa"][reorg_status??0]
    }

    xpRhIcna2016(agent, ps) {
        let montant = 0;
        if (["élève","stagiaire", "normal"].includes(agent.grade)) {
            return montant;
        }
        if (agent.xpRH && agent.xpRHOption) {
            return this.getXpRhMontant(agent.xpRHOption, agent.xpRHGroupe, agent.xpRHCyclesRH)
        } else if (agent.xpRHFromSite) {
            let option = this.dp.xpRH2016[agent.xpRHFromSite];
            let xpRHGroupe;
            if (typeof option == "object"
                && "groups" in option) {
                for (let i = 0; i < option.groups.length; i++) {
                    if (option.groups[i].includes(agent.site)) {
                        xpRHGroupe = i;
                        break;
                    }
                }
            }
            return this.getXpRhMontant(agent.xpRHFromSite, xpRHGroupe, agent.xpRHCyclesRH)
        }
        return montant;
    }

    getXpRhMontant(xpRHOption, groupe, cyclesRH) {
        let montant = 0;
        let option = this.dp.xpRH2016[xpRHOption];

        if (typeof option == "number") {
            montant = option;
        }
        if (typeof option == "object"
                && "groups" in option
                &&  groupe in option) {
            montant = option[groupe];

            if (xpRHOption == "1bis") {
                if (cyclesRH > 4) {
                    let bonusAnnuel = this.dp.xpRH2016["bonus1bis"][groupe] * Math.max(0, cyclesRH - 4)
                    montant += bonusAnnuel / 12;
                }
            }
        }
        return montant
    }
}

class IndemResidence extends Bonus {
    compute(agent, ps) {
        let indem = 0;
        let indiciaire = ps.traitement_brut + ps.nbi;
        let temp = agent.region / 100 * indiciaire;
        if(!isNaN(temp)) {
            indem = temp;
        }
        return new Payline("indem",102000,"INDEMNITE DE RESIDENCE",indem);
    }
}

class IndemAlsace extends Bonus {
    compute(agent, ps) {
        let v = 0;
        if (["LFSB", "LFST","LFJL"].includes(agent.site)) {
            if (ps.indice_brut < 341) {
                v = 1.83;
            } else if ((ps.indice_brut < 770)) {
                v = 2.28;
            } else {
                v = 3.05;
            }
        }
        return new Payline('ida', 200113, "IND. DIFFICULTES ADMINS.", v);
    }
}

class MajoIndexation {
    constructor(assiette, taux, ref, desc) {
        this.assiette = assiette;
        this.taux = taux;
        this.ref = ref;
        this.desc = desc;
    }
    applique(agent, ps) {
        const val = ps[this.assiette] * this.taux / 100;
        return new Payline(null, this.ref, this.desc, val, '+');
    }
}

const majoAGSPM = new MajoIndexation('traitement_brut', 40, 200141, 'MAJORATION TRAITEMENT 40%')
const domtomDesc = {
    FMEE: [
      new MajoIndexation('traitement_brut', 35*1.138, 200142, 'MAJORATION TRAITEMENT 35%'),
      new MajoIndexation('traitement_net', 13.8, 200143, 'INDEXATION REUNION')
    ],
    LFVP:
        [
            majoAGSPM,
            //TODO maj code
            new MajoIndexation('traitement_net', 30.67, getFakeCode(1), 'Majo SPM')
        ],
    SOCA: majoAGSPM,
    TFFF: majoAGSPM,
    TFFR: majoAGSPM,
}

class MajorationDOMTOM extends Bonus {
    compute(agent, ps) {
        if (agent.majDOMTOM) {
            return this.computeLegacy(agent, ps);
        }
        if (agent?.site in domtomDesc) {
            let desc = domtomDesc[agent.site];
            if (Array.isArray(desc)) {
                return desc.map((d) => d.applique(agent, ps));
            }
            return desc.applique(agent, ps);
        }
    }

    computeLegacy(agent, ps) {
        var outremer = 0;
        if (agent.majDOMTOM && agent.majDOMTOM in this.dp.domtom) {
            let rate = this.dp.domtom[agent.majDOMTOM];
            outremer = (rate-1) * ps.traitement_brut;
        }
        return new Payline('outremer', getFakeCode(1), "Majoration outremer", outremer);
    }
}

class RemboursementDomicileTravail extends Bonus {
    compute(agent) {
        var v = 0;
        let temp = parseFloat(agent.rembt);
        if(!isNaN(temp)) {
            v = temp;
        }
        return new Payline("rembt",200033,"REMBT DOMICILE-TRAVAIL ", v, 'horsimpot');
    }
}

class ForfaitNuit extends Bonus {
    compute(agent) {
        let v = 0;
        v = (agent.nuit || 0) * this.dp.indemnite_nuit;
        return new Payline('nuit', 200176, "IND. TRAVAIL DE NUIT", v);
    }
}
class ForfaitTeletravail extends Bonus {
    compute(agent) {
        let v = 0;
        v = (agent.forfaitTeletravail || 0) * this.dp.indemnite_teletravail;
        return new Payline('teletravail', 200042, "FORFAIT TELETRAVAIL", v, 'horsimpot');
    }
}

//pcs : majoration de la part fonction non incluse dans le plafonnement à 120%
class PCS extends Bonus {
    compute(agent) {
        let v = 0;
        if (agent.siteData?.pcs == "1") {
            v = this.dp.pcs[150];
        }
        if (agent.pcs) {
            //force une option pcs
            var pcsOption = agent.pcs
            if(typeof pcsOption == "undefined" || pcsOption === "" || pcsOption === "0") {
                // pas de pcs ?
            } else {
                v = this.dp.pcs[pcsOption]
            }
        }
        return new Payline("pcsValue",201987, "RIST MAJO GEO N/N-E", v);
    }
}

class TransfertPrimePoint extends Bonus {
    compute(agent) {
        //transfert primes/points
        let cat = agent.corps == "tseeac" ? "B":"A";
        var transfert = this.dp.get_yearly_data(this.dp.transfertRetenue[cat]);
        return new Payline('transfert', 604970, "TRANSFERT PRIMES / POINTS", transfert, "-");
    }
}
class CotisPatronaleSecu extends Bonus {
    compute(agent, ps) {
        let indiciaire = ps.traitement_brut + ps.nbi;
        let assietteSecu = indiciaire;
        let famille = assietteSecu * .0525;
        let fnal = assietteSecu * 0.005;
        let autonomie = assietteSecu * 0.003;
        let maladie = assietteSecu * .097;
        let ati = assietteSecu * 0.0032;
        let versementMobilite = 0;
        if (agent?.siteData?.mobilite) {
            versementMobilite = assietteSecu * parseFloat(agent.siteData.mobilite) / 100;
        }

        return [
            new Payline("cotisFamille", 403301, " COTIS PATRON. ALLOC FAMIL", famille, "0"),
            new Payline("cotisLogement", 403501, "COT PAT FNAL DEPLAFONNEE ", fnal, "0"),
            new Payline("cotisAutonomie", 403801, "CONT SOLIDARITE AUTONOMIE ", autonomie, "0"),
            new Payline("cotisMaladie", 404001, "COT PAT MALADIE DEPLAFON", maladie, "0"),
            new Payline("cotisInvalidite", 411058, "CONTRIBUTION ATI", ati, "0"),
            new Payline("vrstMobi", 554500, "COT PAT VST MOBILITE", versementMobilite, "0"),
        ];
    }
}

/** Protection Sociale Complémentaire
 * participation à la mutuelle pour les contrates individuels
 */
class PSCMutuelle extends Bonus {
    compute(agent, ps) {
        let v = agent.psc ? 15 : 0;
        return new Payline('psc', 202354,"PARTICIPATION A LA PSC", v, "+");
    }
}

class RISTTechnique extends Bonus {
  compute(agent, ps) {
    //part technique
    let paylines = [];
    var partTech = 0;
    var partLic = 0;
    var cpltPartLic = 0;
    var partTechPQH = 0;
    var cotisATC = 0;

    let corps = agent.corps;
    let grade = agent.grade;
    let echelon = agent.echelon;
    switch(corps) {
        case "ieeac":
            if(typeof grade != "undefined" && typeof echelon != "undefined" && !isNaN(parseInt(echelon))) {
                switch (grade) {
                    case "élève":
                        //pas de part tech
                        break;
                    case "normal":
                    case "principal":
                    case "hors classe":
                        partTech = this.dp.partTechIEEAC[grade][echelon];
                        break;
                    case "détaché":
                        var grilleDetach = agent.detachement;
                        if(typeof grilleDetach != "undefined"){
                            switch (grilleDetach) {
                                case "CSTAC":
                                case "CTAC":
                                    partTech = this.dp.partTechIEEAC[grilleDetach][echelon];
                                    break;
                                default:
                                    partTech = this.dp.partTechIEEAC["détaché_autre"]["1"];
                            }
                        }
                }
            }
            paylines.push(new Payline('partTech', 201989, 'RIST PART TECHNIQUE PEE', partTech))
            break;
        case "iessa":
            var niveauPEQ = parseInt(agent.peq||0);
            if (!isNaN(niveauPEQ) && niveauPEQ in this.dp.partTechIESSA) {
                partTech = this.dp.partTechIESSA[niveauPEQ];
            }
            paylines.push(new Payline('partTech', 201990, 'RIST PART TECHNIQUE PEQ', partTech))
            break;
        case "icna":
            var niveauLic = parseInt(agent.licence||0);

            if (!isNaN(niveauLic) && niveauLic in this.dp.partLicence) {
                partLic = this.dp.partLicence[niveauLic];

                if (niveauLic >= 4 && !["élève", "stagiaire", "normal"].includes(agent.grade)) {
                    if (agent.groupe in this.dp.complementPartLicence) {
                        cpltPartLic = this.dp.complementPartLicence[agent.groupe];
                    }
                }

                let ISQ = partLic;
                cotisATC = Math.floor(24.6 * ISQ) / 100;
            }
            paylines.push(
                new Payline('partLic', 201960, 'RIST PART LIC-ISQ (ICNA)', partLic),
                new Payline('cpltPartLic', 201961, 'RIST CPLT PART LIC-ISQ', cpltPartLic),
                new Payline('cotisATC', 751095, '24,6% ISQ', cotisATC, '-'),
            );
            break;
        case "tseeac":
            let decodeStep = {
                0:0,
                1:0,
                2:1,
                3:1,
                4:2,
                5:2,
                6:3,
            }
            var niveauQualif = decodeStep[parseInt(agent.TSEtape||0)];
            if (agent.TSEtape==6 && agent.grade == "détaché" && ["CTAC","CSTAC"].includes(agent.detachement)) {
                niveauQualif = 4;
            }
            if (!isNaN(niveauQualif) && niveauQualif in this.dp.partTechTSQualif) {
                partTechPQH = this.dp.partTechTSQualif[niveauQualif];
            }
            if (agent.part3 == "controle") {
                let groupe = agent.tsgroupe ?? agent.groupe;
                if (groupe) {
                    let mapGroupeToNiveauLic = {
                        "G": 2,
                        "F": 1,
                    }
                    if (groupe in mapGroupeToNiveauLic) {
                      niveauLic = mapGroupeToNiveauLic[groupe];
                      partLic = this.dp.partLicence[niveauLic];
                     } else {
                      /* pas d'habilitation technique et pas de groupe de terrain => spécialiste dans un service DSNA */
                      partLic = 0;
                     }
                }
            } else {

                var niveauHabil = parseInt(agent.partTechTSHabil||0);
                if (!isNaN(niveauHabil) && niveauHabil in this.dp.partTechTSHabil) {
                    partTech += this.dp.partTechTSHabil[niveauHabil];
                }
            }
            paylines.push(
                new Payline('partLic', 201988, 'RIST PART LIC-ISQ (TS)', partLic),
                new Payline('partTech', 201992, 'RIST PART TECHNIQUE PQH 2', partTech),
                new Payline('partTechPQH', 201991, 'RIST PART TECHNIQUE PQH 1', partTechPQH),
            )
        default:
    }
    if (typeof(partTech) == "string") {
        partTech = parseFloat(partTech)
    }
    return paylines;
  }
}

function round2Cents(a) {
    return Math.round(a*100)/100;
}