
import { sites } from "./datas/generated.js"

export function getSitesFromCorps(name) {
    let acc = [];
    Object.keys(parsedSites).forEach(key => {
        let s = parsedSites[key];
        if (s.corps == "tous"
            || (Array.isArray(s.corps) && s.corps.includes(name))
            || s.corps === name) {
                acc.push(s);
        }
    });
    return acc;
};

export function getSiteFromId(id) {
    return parsedSites[id];
}

export function getAllSites() {
  return Object.values(parsedSites);
}

const grandCentre = ["LFFF", "LFEE", "LFMM", "LFBB", "LFRR", "LFPG", "LFPO", "CESNAC"]
const siege = [ ... grandCentre, "LFQQ", "LFST", "LFRS", "LFBD", "LFBO", "LFLL", "LFML", "LFMN"]

class Site {
  constructor(obj) {
    Object.assign(this, obj);
  }
  static fromJSON(jsonSite) {
    let clone = {... jsonSite}
    if (jsonSite.corps.indexOf(';') != -1) {
        clone.corps = jsonSite.corps.split(';');
    }
    if (jsonSite.transport) {
        clone.transport = {}
        jsonSite.transport.split(';').forEach(t => {
            let [abo,cout] = t.split(':')
            clone.transport[abo] = parseFloat(cout);
        })
    }
    return new Site(clone);
  }

  estSiege() {
    return siege.includes(this.oaci);
  }
  estGroupeA() {
    return this.groupe[0] == 'A';
  }
  estCRNA() {
    return ['LFRR', 'LFFF', 'LFEE', 'LFBB', 'LFMM'].includes(this.oaci);
  }
}

let parsedSites = {}

sites.forEach(s => {
    if (! s.nom) {
        return;
    }
    parsedSites[s.oaci] = Site.fromJSON(s);
})