<template>
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<div class="comp">
  <div class="form-block">
    <CorpsPicker
      :items="['iessa','ieeac','icna','tseeac']"
      v-model=input.corps
    />

  <SitePicker
    :corps="input.corps"
    :currentSite="input.site"
    withMap
    filterByCorps
    @site="receiveSite"
  />

  </div>

 <div class="form-block">
   <CarriereGeneric @carriere="copieCarriere" :corps="input.corps" />
   <AnimatedTextInput label="Indice Majoré" v-model="input.indiceMajo" />
   <CarriereTSEEAC @carriere="copyToInput" v-if="input.corps == 'tseeac'" />
   <PEQIessa v-model="input.peq" v-if="input.corps === 'iessa'" />
   <CarriereICNA @carriere="copyToInput" v-show="input.corps == 'icna'" />
  <toggle-button
    label="NBI"
    title="Accordé sous condition, ou au plus tard à 35 ans"
    :map="{true:1,false:0}"
    v-model="input.nbi"
  />
 </div>

  <div class="form-block">
    <h3>Fonction</h3>
    <template v-if="input.site?.length > 0">
    <EVSSelector
      :corps='input.corps'
      :site='input.siteData'
      :grade='input.grade'
      :echelon="input.echelon"
      @evs="receiveEVS"
    />
    </template>
    <p class="fonction-placeholder" v-else>
      Saisissez votre centre et votre grade/échelon pour bénéficier de suggestions.
    </p>
  </div>

  <div class="form-block">
    <h3>Vous</h3>
    <number-input label="Enfants à charge" v-model="input.famille" />
    <transport-select :site="input.siteData" v-model="input.rembt" />
    <number-input label="Compensation CSG" v-model="input.indem_csg" />
    <number-input label="Taux imposition" v-model="input.tauxIR" />
    <toggle-button label="Participation mutuelle" v-model="input.psc" />
  </div>
  <div class="form-block">
    <h3>Ponctuel</h3>
    <number-input label="Jours télétravail" v-model="input.forfaitTeletravail" />
    <number-input label="Nombre de nuits" v-model="input.nuit" />
  </div>

</div>
</template>

<style scoped>
/* layout */
.comp, .form-block {
  grid-column: 1 / span 4;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(110px, 1fr) );
}

.form-block {
  gap: var(--baseline);
  padding: 1em;
  margin-left: -.5em;
  margin-right: 1em;
  border-radius: var(--baseline);
  background-color: var(--secondary-lt-color);
}

.form-block > .number-input,
.form-block > .animated-input,
.form-block > .toggle-button,
.form-block > .abonnement-transport {
  grid-column: span 4;
  display: grid;
  grid-template-columns: subgrid;
}

.form-block > select {
  grid-column: span 3;
}

.form-block > .fonction-placeholder {
  grid-column: span 4;
  color: grey;
}

h3 {
  grid-column: span 4;
  margin-bottom: unset;
}
.recap {
  grid-column: span 4;
}
.recap span {
  display:block;
}

/* fine-grain layout */
.comp {
  gap: var(--baseline-x2);
}
</style>

<script setup>
import { useStore } from 'vuex';

import CorpsPicker from '@/components/input/wizard/CorpsPicker.vue';
import SitePicker from '@/components/input/wizard/SitePicker.vue';
import EVSSelector from '@/components/input/wizard/EVSSelector.vue';
import PEQIessa from '@/components/input/widgets_specific/PEQIessa.vue';
import CarriereTSEEAC from '@/components/input/wizard/CarriereTSEEAC.vue';
import CarriereGeneric from '@/components/input/wizard/CarriereGeneric.vue';
import CarriereICNA from '@/components/input/wizard/CarriereICNA.vue';
import TransportSelect from '@/components/input/widgets_specific/TransportSelect.vue';
import ToggleButton from '@/components/input/widgets_general/ToggleButton.vue';
import NumberInput from '@/components/input/widgets_general/NumberInput.vue';
import AnimatedTextInput from '@/components/input/widgets_general/AnimatedTextInput.vue';

const store = useStore();
const { input } = store.state;

function receiveSite(newSite) {
  store.dispatch('setSite', newSite);
}

function receiveEVS(newEVS) {
  input.evs = newEVS;
}

function copieCarriere(newCarriere) {
  Object.keys(newCarriere).forEach((key) => {
    if (key !== 'name' && key !== 'label') {
      if (key === 'indiceMajo') {
        store.state.deduced[key] = newCarriere[key];
      } else {
        input[key] = newCarriere[key];
      }
    }
  });
}

function copyToInput(newObj) {
  Object.keys(newObj).forEach((key) => {
    if (key !== 'name' && key !== 'label') {
      input[key] = newObj[key];
    }
  });
}

</script>

<script>
export default {
  components: {
    SitePicker,
    ToggleButton,
    TransportSelect,
    NumberInput,
    CarriereGeneric,
  },
  name: 'SimpleForm',
};
</script>
