<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
  <div class="paysheet-container">
    <table class="above-paysheet">
      <thead>
        <tr>
          <td>IDENTIFICATION</td>
          <td>GRADE</td>
          <td>ENFANTS À CHARGE</td>
          <td>ÉCH</td>
          <td>INDICE OU NB. D' HEURES</td>
          <td>TAUX HORAIRE OU NBI</td>
          <td>TEMPS PARTIEL</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>///</td>
          <td>{{input.corps}} / {{input.grade}}</td>
          <td>{{input.famille.toString().padStart(2, '0')}}</td>
          <td>{{input.echelon.toString().padStart(2, '0')}}</td>
          <td>{{paysheet.indice}}</td>
          <td>{{input.nbi ? paysheet.nbi_points.toString().padStart(3, '0') : ''}}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th>CODE</th>
          <th>ÉLÉMENTS</th>
          <th>À PAYER</th>
          <th>À DÉDUIRE</th>
          <th>POUR INFORMATION</th>
        </tr>
      </thead>
      <tbody>
        <template
            v-for="line in sortedLines"
            :key="line.ref"
        >
        <tr v-if="line.amount > 0"
          :id="`row${line.ref}`"
          @click="link(line)"
          @mouseover="over(line)"
          @mouseleave="leave(line)"
          :class="{clickableLine: line.wikilink}"
          data-tooltip="Plus d'info sur le wiki USAC."
          >
          <td> {{line.ref}}</td>
          <td v-if="line.wikilink">
            <a
              :id="`wikilink${line.ref}`"
              :href="line.wikilink"
              target="_blank" rel="noopener noreferrer"
              onclick="event.stopPropagation();"
            >
              {{line.desc}}
            </a>
          </td>
          <td v-else> {{line.desc}} </td>

          <td>
            <!-- eslint-disable max-len -->
            {{ (line.sign == "+" || line.sign == "horsimpot") && line.amount > 0 ? line.amount.toFixed(2) : ""}}
          </td>
          <td>
            {{ line.sign == "-" && line.amount > 0 ? line.amount.toFixed(2) : ""}}
          </td>
          <td>
            {{ line.sign == "0" && line.amount > 0 ? line.amount.toFixed(2) : ""}}
          </td>
        </tr>
        </template>
        <tr><td v-for="index in 5" :key="index">&nbsp;</td></tr>
        <tr><td v-for="index in 5" :key="index">&nbsp;</td></tr>
        <tr>
          <td>011100</td>
          <td>NET A PAYER AVANT IMPOT SUR LE REVENU</td>
          <td />
          <td />
          <td>{{paysheet.total}}</td>
        </tr>
        <tr v-if="paysheet.retenueIR">
          <td>558000 </td>
          <td>IMPOT SUR LE REVENU PRELEVE A LA SOURCE</td>
          <td />
          <td>{{paysheet.retenueIR}}</td>
          <td />
        </tr>
        <tr><td v-for="index in 5" :key="index">&nbsp;</td></tr>
        <tr><td v-for="index in 5" :key="index">&nbsp;</td></tr>
        <!-- <tr>
          <td></td>
          <td>{{(paysheet.total_pos + paysheet.cotisPatronales - paysheet.lines[604970].amount).toFixed(2)}}</td>
          <td>{{paysheet.total}}</td>
          <td>{{(paysheet.retenues+paysheet.retenueIR).toFixed(2)}}</td>
          <td>{{paysheet.cotisPatronales.toFixed(2)}}</td>
        </tr> -->
      </tbody>
    </table>
    <div class="bottom">
      <div class="bottomLeft">
      </div>
      <div class="bottomRight">
        NET À PAYER <span class="netir">{{paysheet.netir}}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.paysheet-container {
    display:unset;
    grid-column: span 8;
}
table {
    table-layout: auto;
}
thead {
    background-color: #4B5F74;
    text-align: center;
    color: white;
}
tbody > tr:nth-child(even) {
    background-color: #DADFEB;
}

tbody > tr > td:nth-child(n+3) {
    text-align: right;
}

tr.clickableLine {
  cursor: pointer;
}

.above-paysheet > thead > tr> td, .above-paysheet > tbody > tr> td {
  border-right: 1em;
  border-right-style: solid;
  border-right-color: white;
}
.above-paysheet > thead {
  font-size: .6em;
}

.bottom {
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #DADFEB;
}
.bottomRight {
  color: #4B5F74;
  grid-column: span 2;
  padding: 2em;
}
.netir {
  color: black;
  background: white;
  font-weight: 600;
  border-style: none solid solid;
  border-width: 1px;
}

tr.clickableLine::after {
  content: attr(data-tooltip);
  width: 40em;
  margin-top: 1.8em;
  padding: 5px;
  font-size: 12px;
  background-color: rgba(100, 100, 100, 0.9);
  border-radius: 4px;
  /* transform: translate3d(0, -100%, 0); */
  pointer-events: none;
}

tr.clickableLine::after {
   position: absolute;
   left: 50%;
   /* top:2em; */
   opacity: 0;
   transition: all 0.2s ease-out;
}
tr.clickableLine:hover::after {
  opacity: 1;
}
</style>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ViewerPaye',
};
</script>

<script setup>

const store = useStore();
const { input } = store.state;

const paysheet = computed(() => store.getters.paysheet);

const sortedLines = computed(
  () => Object.values(paysheet.value.lines).sort((a, b) => a.ref > b.ref),
);

function link(line) {
  document.getElementById(`wikilink${line.ref}`)?.click();
}

const timeouts = {};
/* chargement de l'extrait wiki uniquement lors d'une pause sur une ligne */
function over(line) {
  if (line.wikiTitle && !timeouts[line.ref]) {
    timeouts[line.ref] = window.setTimeout(() => updateTitle(line), 400);
  }
}

function leave(line) {
  clearTimeout(timeouts[line.ref]);
  delete timeouts[line.ref];
}

async function updateTitle(line) {
  const extract = await line.wikiextract();
  if (extract) {
    const row = document.getElementById(`row${line.ref}`);
    row.setAttribute('data-tooltip', `${extract}\nPlus d'info sur le wiki USAC.`);
  }
}

</script>
